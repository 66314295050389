import logo from './logo.svg';
import './App.css';
import ThreeD from './components/3d';

function App() {
  return (
    <div className='three-d-container'>
      <ThreeD />
    </div>
  );
}

export default App;
